import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPaper } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

import SEO from "../components/Seo/Seo"
import "../styles/pages/contact.scss"

const ContactPage = ({ location }) => {
  const { i18n, t } = useTranslation();
  const openIntercom = () => {
      if (window.Intercom) {
          window.Intercom('showMessages');
      }
  };

  return (
    <>
      <SEO
        title={t("navigation.contact")}
        lang={i18n.language}
        pathname={location.pathname}
        description={t("meta.contact.description")}
      />
      <section className="Contact">
        <div className="container text-center">
          <h1 className="text-white">{t("contactPage.title")}</h1>
          <p className="text-white pb-4">{t("contactPage.subtitle")}</p>
          <button className="btn btn-success" onClick={openIntercom}>
            <FontAwesomeIcon icon={faHandPaper} fixedWidth width="14" className="mr-1" />
            {t("contactPage.buttonLabel")}
          </button>
          <p className="sub-text text-white">
            {t("contactPage.emailUs")}&nbsp;
            <a className="link" href="mailto:support@schedulino.com">
              support@schedulino.com
            </a>
          </p>
        </div>
      </section>
    </>
  )
};

export default ContactPage
